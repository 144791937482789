<template>
  <div>
    <ShopBrands />
  </div>
</template>

<script>
import ShopBrands from '@/components/Shop/ShopBrands.vue';

export default {
  components: {
    ShopBrands
  }
}
</script>

<style scoped>

</style>