<template>
  <div class="page container-lg">
    <h2>{{ $t('yourCart') }}</h2>
    <div v-if="cartItems.length > 0">
      <div
        v-for="(item, index) in cartItems"
        :key="item.id"
        class="cart-item"
      >
        <img
          :src="item.images[0]"
          alt="product"
          width="50"
        >
        <div class="productContent">
          <h2>
            {{ item.name }}
          </h2>
          <!-- <p class="description">
            {{ truncateDescription(item.description) }}
          </p> -->
          <p class="quantity">
            {{ $t('quantity') }} {{ item.quantity }}
          </p>
          <p>
            {{ $t('price') }} {{ (item.size ? item.sizes.find(size => size.size === item.size)?.price : item.price) * item.quantity }}€
          </p>
        </div>
        <button
          class="remove"
          @click="handleRemoveProduct(index)"
        >
          {{ $t('remove') }}
        </button>
      </div>

      <hr>
      <h1 style="margin-top: 5%;">
        {{ $t('proceedToCheckout') }}
      </h1>
      
      <form
        ref="form"
        @submit.prevent="sendEmail"
      >
        <div class="form-grid">
          <div class="form-group">
            <label for="firstName">{{ $t('fullName') }}</label>
            <input
              id="firstName"
              v-model="form.firstName"
              name="fullName"
              type="text"
              placeholder="John Smith"
              required
            >
          </div>

          <div class="form-group">
            <label for="lastName">{{ $t('address') }}</label>
            <input
              id="lastName"
              v-model="form.address"
              name="lastName"
              type="text"
              placeholder="Prishtine, rr. 28 Nentori"
              required
            >
          </div>

          <div class="form-group">
            <label for="email">Email</label>
            <input
              id="email"
              v-model="form.email"
              name="email"
              type="email"
              placeholder="johnsmith@example.com"
              required
            >
          </div>

          <div class="form-group">
            <label for="phone">{{ $t('phoneNumber') }}</label>
            <input
              id="phone"
              v-model="form.phoneNumber"
              name="phoneNumber"
              type="tel"
              placeholder="+3834967890"
              required
            >
          </div>
        </div>
        <div class="form-submit">
          <button
            type="submit"
            value="Send"
          >
            {{ $t('submit') }}
          </button>
        </div>
      </form>

      <div
        v-if="orderSuccess"
        class="success-message"
      >
        <p>{{ $t('orderSuccessMessage') }}</p>
      </div>
    </div>

    <!-- If cart is empty -->
    <div v-else>
      <p>{{ $t('emptyCart') }}</p>
      <p>
        <router-link to="/shop">
          {{ $t('backToShop') }}
        </router-link>
      </p>
    </div>
  </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex';
import emailjs from '@emailjs/browser';

export default {
  data() {
    return {
      form: {
        firstName: null,
        address: null,
        email: null,
        phoneNumber: null,
      },
      orderSuccess: false,
    }
  },
  computed: {
    ...mapGetters('shop', ['getCartItems']),
    cartItems() {
      return this.getCartItems || [];
    },
  },
  methods: {
    ...mapActions('shop', ['removeProduct', 'addToCart', 'clearCart']),
    handleRemoveProduct(index) {
      this.removeProduct({ idx: index });
    },
    sendEmail() {
      const cartItemsHtml = this.cartItems.map(item => {
        const price = item.price !== undefined
          ? item.price
          : item.sizes && item.sizes[0] && item.sizes[0].price;
        const totalPrice = price * item.quantity;

        return `
          ${item.name}
          Quantity: ${item.quantity}
          Price: ${totalPrice !== undefined ? totalPrice : 'N/A'}€
        `;
      }).join('');

      const templateParams = {
        firstName: this.form.firstName,
        address: this.form.address,
        email: this.form.email,
        phoneNumber: this.form.phoneNumber,
        cartItems: cartItemsHtml
      };

      emailjs.send('service_u4h6cwl', 'template_5lbhqss', templateParams, 'lnvL4Yqvd2YQwH7A_')
        .then((result) => {
          console.log('SUCCESS!', result.text);

          this.form = {
            firstName: null,
            address: null,
            email: null,
            phoneNumber: null,
          };
          this.orderSuccess = true;

      setTimeout(() => {
        this.clearCart();
        this.orderSuccess = false; 
      }, 3000);
    }, (error) => {
      console.log('FAILED...', error.text);
    });
    },
    // truncateDescription(description) {
    //   return description.length > 200 ? description.slice(0, 200) + '...' : description;
    // }
  },
}
</script>

<style scoped>
.page {
  padding-top: 80px;
  padding-bottom: 80px;

}
.cart-item {
  display: flex;
  margin-bottom: 20px;
}
.cart-item img {
  margin-right: 10px;
  width: 25%;
  height: 100%;
}
.remove {
  width: 80px;
  height: 37px;
  border: none;
  background: #89d0f0;
  color: white;
  border-radius: 6px;
}
.productContent {
  width: 70%;
  margin-left: 20px;
}
.form-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
.form-group {
  display: flex;
  flex-direction: column;
}
label {
  margin-bottom: 5px;
  font-weight: bold;
}
input {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.form-submit button {
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #89d0f0;
  border: none;
  border-radius: 6px;
  cursor: pointer;
}
.form-submit {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
.success-message {
  color: green;
  font-weight: bold;
  margin-top: 10px;
}
@media screen and (max-width: 1000px) {
  
  .form-grid {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .cart-item {
    display: flex;
    justify-content: center;
  }
  .cart-item img {
    width: 30%;
    margin: 0px;
  }
  .productContent {
    margin: 0;
    width: 100%;
  }
  .productContent .description {
    display: none;
  }
  .productContent h2 {
    font-size: 16px;
    font-weight: 700;
  }
  .productContent .quantity {
    font-size: 14px;
  }
}

</style>
