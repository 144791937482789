<template>
  <div class="services container-lg">
    <div class="services-container">
      <div class="services-line">
        <router-link to="/services/test">
          <div class="img-wrapper">
            <img
              src="@/assets/images/ElemisBiotecThefivetech.svg"
              alt="services"
            >
          </div>
        </router-link>
        <router-link to="/services/elastine-boost">
          <div class="img-wrapper">
            <img
              src="@/assets/images/ElemisBiotecTripleTech.svg"
              alt="services"
            >
          </div>
        </router-link>
      </div>
      <div class="services-line">
        <router-link to="/services/anti-redness">
          <div class="img-wrapper">
            <img
              src="@/assets/images/ElemisBiotecTheDoubleTech.svg"
              alt="services"
            >
          </div>
        </router-link>
        <router-link to="/services/fire-ice-facial">
          <div class="img-wrapper">
            <img
              src="@/assets/images/iSClinicalFireIceFacial.svg"
              alt="services"
            >
          </div>
        </router-link>
      </div>
      <div class="services-line">
        <router-link to="/services/enzyme-masque">
          <div class="img-wrapper">
            <img
              src="@/assets/images/FoamingEnzymeMasqueSystem.svg"
              alt="services"
            >
          </div>
        </router-link>
        <router-link to="/services/sturmglow">
          <div class="img-wrapper">
            <img
              src="@/assets/images/barbaraSturmGlow.svg"
              alt="services"
            >
          </div>
        </router-link>
      </div>
      <div class="services-line">
        <router-link to="/services/chemical-peel">
          <div class="img-wrapper">
            <img
              src="@/assets/images/TheChemicalPeel.svg"
              alt="services"
            >
          </div>
        </router-link>
        <router-link to="/services/face-physical-training">
          <div class="img-wrapper">
            <img
              src="@/assets/images/TheFacePhysicalTraining.svg"
              alt="services"
            >
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      norms: 'GLOW IS THE ESSENCE OF BEAUTY'
    };
  }
};
</script>

<style scoped>
.services {
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  padding-block: 80px;
}

/* .services-container {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
} */


.services-line {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.img-wrapper {
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
  transition: background-color 0.3s ease;
}

.img-wrapper:hover {
  background-color: #89d0f0;
}

.services-line img {
  width: 100%;
  border-radius: 20px;
  transition: opacity 0.3s ease;
}

.img-wrapper:hover img {
  opacity: 0.5;
}


@media (max-width: 600px) {
  .services-line {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
}
</style>
