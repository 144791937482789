import { createApp } from "vue";
import { createI18n } from "vue-i18n";
import VueSplide from "@splidejs/vue-splide";
import AOS from "aos";
import "aos/dist/aos.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "../node_modules/vue3-marquee-slider/dist/style.css";
import en from "./i18n/en.json";
import al from "./i18n/al.json";

import App from "./App.vue";
import router from "./router";
import store from "./store";

const savedLanguage = localStorage.getItem("language") || "en";

const i18n = createI18n({
  locale: savedLanguage,
  fallbackLocale: "en",
  messages: {
    en,
    al,
  },
});

store.watch(
  (state) => state.language.lang,
  (value) => {
    i18n.global.locale = value;
  }
);

const app = createApp(App);
app.use(i18n);
app.use(store);
app.use(router);
app.use(VueSplide);

app.mount("#app");

AOS.init();