export default {
  namespaced: true,
  state: () => ({
    currentLanguage: localStorage.getItem("language") || "en",
  }),
  getters: {
    getLanguage: (state) => state.currentLanguage,
  },
  mutations: {
    setLanguage(state, payload) {
      state.currentLanguage = payload;
      localStorage.setItem("language", payload); 
    },
  },
  actions: {
    changeLanguage({ commit }, payload) {
      commit("setLanguage", payload);
    },
  },
};
