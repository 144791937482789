<template>
  <div class="app__negative-headers">
    <div
      v-if="service"
      :style="{ backgroundImage: `url(${service.image})` }"
      class="background"
    >
      <ServiceDetailsImage :data="service" />
    </div>
    <div v-else>
      <p>Loading service details...</p>
    </div>
    <DetailsServices
      v-if="service"
      :list="service.items"
    /> 
  </div>
</template>

<script>
import ServiceDetailsImage from '@/components/ServicesDetails/ServiceDetailsImage.vue'
import DetailsServices from '@/components/ServicesDetails/DetailsServices.vue'
import { services } from '@/api/services';
import { mapGetters } from 'vuex';


export default {
components: {
  ServiceDetailsImage,
  DetailsServices
},
data() {
    return {
      service: null,
    }
  },
  computed: {
    ...mapGetters('language', ['getLanguage']),
    currentLanguage() {
      return this.$store.state.language.currentLanguage;
    },
  },
  watch: {
    currentLanguage(newLang) {
      this.loadService(newLang);
    },
  },
  created() {
    this.loadService(this.currentLanguage);
  },
methods: {
  async loadService(language) {
  let servicesModule;
  try {
    if (language === 'al') {
      servicesModule = await import('@/api/servicesAl'); // Albanian services
    } else {
      servicesModule = await import('@/api/services'); // English services
    }
    const services = servicesModule.services; 
    const serviceId = this.$route.params.id;
    this.service = services[serviceId] || null;

  } catch (error) {
    console.error("Error loading services:", error);
  }
}
}
}
</script>

<style scoped>
.background {
  height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  transition: background-image 0.5s ease-in-out;
}
</style>