<template>
  <div>
    <div class="logoAbout">
      <img
        src="@/assets/images/CIEL.svg"
        alt="Ciel logo"
      >
    </div>
    <div class="introductory">
      <h2>{{ $t('aboutUs') }}</h2>
      <div class="vertical-line" />
      <span style="color: #A99175; font-size: 14px; margin: 3% 0%;">{{ norms }}</span>
      <p>{{ $t('aboutCielHome') }}</p>
      <p>{{ $t('IntroducingCiel') }} </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      norms: 'GLOW IS THE ESSENCE OF BEAUTY'
    }
  }
}
</script>

<style scoped>
.introductory {
  width: 60%;
  margin: auto;
  display: flex;
  flex-direction: column;
  text-align: center;
}
.logoAbout {
  display: flex;
  justify-content: center;
  margin: 5% 0%;
}
.logoAbout img {
  width: 13%;
  height: 100%;
}
.introductory h2 {
  font-size: 37px;
}

.vertical-line {
  width: 1px;
  height: 70px;
  background: #A99175;
  display: flex;
  justify-content: center;
  margin: auto;
}

@media screen and (max-width: 1000px) {
  .introductory {
    width: 85%;
    margin: auto;
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  
  .logoAbout img {
    width: 60%;
  }
}
</style>