<template>
  <div class="brand_container container-lg">
    <div class="ciel_brands">
      <div
        class="brand"
        @click="selectBrand('Elemis')"
      >
        <img
          src="@/assets/images/elemisLogo.svg"
          alt="Elemis logo"
        >
      </div>
      <div
        class="brand"
        @click="selectBrand('Rodial')"
      >
        <img
          src="@/assets/images/RodialLogo.svg"
          alt="Rodial logo"
        >
      </div>
      <div
        class="brand"
        @click="selectBrand('Dr.Barbara')"
      >
        <img
          src="@/assets/images/strumLogo.svg"
          alt="Sturm Logo"
        >
      </div>
      <div
        class="brand"
        @click="selectBrand('K18')"
      >
        <img
          src="@/assets/images/K18Logo.svg"
          alt="K18 logo"
        >
      </div>
      <div
        class="brand"
        @click="selectBrand('iS Clinical')"
      >
        <img
          src="@/assets/images/iSClinical.svg"
          alt="Hampton Sun Logo"
        >
      </div>
      <div
        class="brand"
        @click="selectBrand('Hampton Sun')"
      >
        <img
          src="@/assets/images/HamptonSunLogo.svg"
          alt="Hampton Sun Logo"
        >
      </div>
      <!-- <div
        class="brand"
        @click="selectBrand('Bron')"
      >
        <img
          src="@/assets/images/BronLogo.svg"
          alt="Bron Logo"
        >
      </div> -->
      <div
        class="brand"
        @click="selectBrand('Thera Body')"
      >
        <img
          src="@/assets/images/TherabodyLogo.svg"
          alt="Therabody Logo"
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    selectBrand(brand) {
      this.$router.push({ name: "BrandProducts", params: { brand } });
    },
  },
};
</script>

<style scoped>
.brand_container {
  padding-top: 80px;
  padding-bottom: 80px;
}

.ciel_brands {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 20px;
}

.brand {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f4f4f4;
  border-radius: 27px;
  transition: filter 0.2s ease;
}

.brand img {
  max-width: 80%;
  max-height: 70%;
  object-fit: contain;
}

.brand:hover {
  filter: blur(3px);
}

@media screen and (max-width: 600px) {
  .ciel_brands {
    grid-template-columns: 1fr;
  }

  .brand {
    height: 200px;
  }

  .brand img {
    max-width: 90%;
  }
}

@media screen and (max-width: 800px) {
  .brand {
    width: 90%;
    margin: auto;
    height: 220px;
  }

  .brand img {
    max-width: 80%;
  }
}

</style>
